import React, { useState } from 'react';
import MarkdownViewer from '../../MdViewer';
import { Box, Button, Flex, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack, IoLanguage } from 'react-icons/io5';

const CustomModelImport = () => {
  const [language, setLanguage] = useState<'cn' | 'en'>('cn');
  const navigate = useNavigate();

  return (
    <Box position="relative" p={4}>
      <Flex justifyContent="space-between" mb={4}>
        <Button
          colorScheme="blue"
          onClick={() => navigate('/')}
          leftIcon={<Icon as={IoArrowBack} />}
        >
          Back
        </Button>
        <Button
          colorScheme="blue"
          onClick={() => setLanguage(language === 'cn' ? 'en' : 'cn')}
          leftIcon={<Icon as={IoLanguage} />}
        >
          {language === 'cn' ? 'English' : '中文'}
        </Button>
      </Flex>

      {language === 'cn' ? (
        <MarkdownViewer file="local-model-import-cn.md" />
      ) : (
        <MarkdownViewer file="local-model-import-en.md" />
      )}
    </Box>
  );
};

export default CustomModelImport;
