import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  AspectRatio,
  Center,
  Heading,
  GridItem,
  Flex,
  SimpleGrid,
  extendTheme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import MarkdownViewer from './MdViewer';
import { Link as RouterLink, Routes, Route } from 'react-router-dom';
import CustomModelImport from './pages/custom-model-import/index.tsx';

function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const path = window.location.pathname;
  useEffect(() => {
    console.log(path);
    if (path === '/privacy-policy') {
      setTabIndex(3);
    }
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/custom-model-import" element={<CustomModelImport />} />
        <Route path="*" element={
          <>
            <Heading
              style={{
                position: 'absolute',
                left: '20px',
                top: 0,
                zIndex: 100,
                margin: 0,
              }}
            >
              HoloDeck
            </Heading>
            <Tabs align="end" onChange={index => setTabIndex(index)} index={tabIndex}>
              <TabList backgroundColor={'#eee'}>
                <Tab>Home</Tab>
                <Tab>About</Tab>
                <Tab>App Help</Tab>
                <Tab>Privacy Policy</Tab>
              </TabList>

              <TabPanels>
                <TabPanel padding={0}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#eee',
                      padding: '20px 0',
                    }}
                  >
                    <Image src="/home.png" alt="home" />
                  </Box>
                  <Center h="100px">
                    <Heading>A new presentation tool</Heading>
                  </Center>
                  <Box style={{ padding: '20px 80px' }}>
                    <AspectRatio ratio={16 / 9}>
                      <video controls poster="/home.png">
                        <source src="https://vanstone.oss-cn-beijing.aliyuncs.com/home_video.mp4" type="video/mp4" />
                      </video>
                    </AspectRatio>
                  </Box>
                </TabPanel>
                <TabPanel padding={0}>
                  <Flex backgroundColor="#eee" padding={'20px'}>
                    <Box flex="1" textAlign={'left'}>
                      <Heading marginBottom={'20px'}>
                        We are Beijing Startwilight Technology Company Limited
                      </Heading>
                      <Heading marginBottom={'20px'}>
                        Address: Room 3020, 3rd Floor, Room 201, 2nd to 4th Floors,
                        Building 4, No. 60, Mapo Section, Baima Road, Shunyi District,
                        Beijing
                      </Heading>
                      <Heading>Email: zhanghuilong@startwilight.com</Heading>
                    </Box>
                    <Center flex="1">
                      <Image src="/img1.jpg" alt="img1" />
                    </Center>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Box mt={4} mb={4}>
                    <Link as={RouterLink} to="/custom-model-import" color="blue.500" fontSize="xl">
                      Local Model Import Guide
                    </Link>
                  </Box>
                  <Center>
                    <Heading marginBottom={'20px'}>Guides</Heading>
                  </Center>

                  <SimpleGrid columns={2} spacing={4}>
                    <Box p={4}>
                      <AspectRatio ratio={16 / 9}>
                        <video controls poster="/home.png">
                          <source src="https://vanstone.oss-cn-beijing.aliyuncs.com/video1.mp4" type="video/mp4" />
                        </video>
                      </AspectRatio>
                    </Box>
                    <Box p={4}>
                      <AspectRatio ratio={16 / 9}>
                        <video controls poster="/home.png">
                          <source src="https://vanstone.oss-cn-beijing.aliyuncs.com/video2.mp4" type="video/mp4" />
                        </video>
                      </AspectRatio>
                    </Box>
                    <Box p={4} >
                      <AspectRatio ratio={16 / 9}>
                        <video controls poster='/home.png'>
                          <source src="https://vanstone.oss-cn-beijing.aliyuncs.com/446_1735186316.mp4" type="video/mp4" />
                        </video>
                      </AspectRatio>
                    </Box>
                    {/* <Box p={4} >
                      <AspectRatio ratio={16 / 9}>
                        <video controls poster='/home.png'>
                          <source src="/video3.mp4" type="video/mp4" />
                        </video>
                      </AspectRatio>
                    </Box>
                    <Box p={4} >
                      <AspectRatio ratio={16 / 9}>
                        <video controls poster='/home.png'>
                          <source src="/video4.mp4" type="video/mp4" />
                        </video>
                      </AspectRatio>
                    </Box> */}
                  </SimpleGrid>
                </TabPanel>
                <TabPanel>
                  <MarkdownViewer file="HoloViewer Privacy Policy.md" />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Center margin={'40px 0'}>
              ©2024 Beijing Startwilight Technology Company Limited
            </Center>
          </>
        } />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
